#root {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.theGrid {
    height: 100%;
    display: inline-grid;
    grid-template-columns: auto;
    grid-template-rows: 370px auto;
}

#DeviceDetection {
    background-color: green;
}